import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  IconButton,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  getCountries,
  getProjects,
  requestNumber,
  getSms,
} from "../services/numbersApi";
import { Country, Project, NumberResponse } from "../types";
import { Close, PhoneIphone } from "@mui/icons-material";

// Function to get flag emoji from country code
const getFlagEmoji = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

const RequestNumberComponent: React.FC<{ onNumberRequested?: Function }> = ({
  onNumberRequested,
}) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<number | "">("");
  const [selectedProject, setSelectedProject] = useState<number | "">("");
  const [requestedNumber, setRequestedNumber] = useState<NumberResponse | null>(
    null
  );
  const [sms, setSms] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesData = await getCountries();
        const projectsData = await getProjects();

        // Sort countries alphabetically by title
        countriesData.sort((a: Country, b: Country) =>
          a.title.localeCompare(b.title)
        );

        // Sort projects alphabetically by title
        projectsData.sort((a: Project, b: Project) =>
          a.title.localeCompare(b.title)
        );

        setCountries(countriesData);
        setProjects(projectsData);

        const defaultCountry = countriesData.find(
          (country) => country.code === "id"
        );

        const defaultProject = projectsData.find(
          (project) => project.code === "wa"
        );

        if (defaultCountry) {
          setSelectedCountry(defaultCountry.id);
        }

        if (defaultProject) {
          setSelectedProject(defaultProject.id);
        }
      } catch (err) {
        setError("Failed to fetch data");
        setSnackbarOpen(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (timeRemaining === null || timeRemaining <= 0) return;

    const timerId = setInterval(() => {
      setTimeRemaining((prev) => (prev !== null ? prev - 1 : prev));
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeRemaining]);

  useEffect(() => {
    if (timeRemaining === 0) {
      setError(
        "Timeout: No SMS received within 5 minutes. Please request a new number."
      );
      setSnackbarOpen(true);
    }
  }, [timeRemaining]);

  const handleRequestNumber = async () => {
    try {
      setLoading(true);
      if (selectedCountry && selectedProject) {
        const numberResponse = await requestNumber(
          selectedCountry,
          selectedProject
        );

        onNumberRequested && onNumberRequested();

        setRequestedNumber(numberResponse);
        setTimeRemaining(300); // Set the timer for 5 minutes
        setOpen(true); // Open the modal

        // Keep trying to get the SMS
        const fetchSms = async () => {
          const smsResponse = await getSms(numberResponse.data.request_id);

          if (smsResponse.data) {
            setSms(smsResponse.data);
            setTimeRemaining(null); // Stop the timer
          } else if (smsResponse.code === 50102) {
            setError("[TIMEOUT] No SMS received, please request a new number.");
            setSnackbarOpen(true);
            setTimeRemaining(null); // Stop the timer
          } else if (
            (smsResponse.code === 50101 || smsResponse.code === 429) &&
            timeRemaining !== 0
          ) {
            setTimeout(fetchSms, 5000);
          }
        };

        fetchSms();
      }
    } catch (err) {
      console.error({ err });
      setError("Failed to request number");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setRequestedNumber(null);
    setTimeRemaining(null);
    setSms(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError(null);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="1rem"
      border="1px solid #E0E0E0"
      borderRadius="12px"
      bgcolor="white"
      boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
    >
      <Box display="flex" alignItems="center">
        <PhoneIphone
          sx={{ color: "#616161", fontSize: "24px", marginRight: "0.5rem" }}
        />
        <Typography
          variant="h6"
          sx={{
            margin: 0,
            color: "#616161",
            fontFamily: "Inter",
            fontSize: "24px",
            fontWeight: 500,
            lineHeight: "36px",
            letterSpacing: "-0.504px",
          }}
        >
          Request New Number
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Country</InputLabel>
          <Select
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value as number)}
            label="Country"
            sx={{ borderRadius: "8px" }}
          >
            <MenuItem value="">
              <em>Select Country</em>
            </MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                <span style={{ marginRight: "8px" }}>
                  {getFlagEmoji(country.code)}
                </span>
                {country.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Project</InputLabel>
          <Select
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value as number)}
            label="Project"
            sx={{ borderRadius: "8px" }}
          >
            <MenuItem value="">
              <em>Select Project</em>
            </MenuItem>
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#2196F3",
            borderRadius: "8px",
            textTransform: "none",
            padding: "0.5rem 1.5rem",
          }}
          onClick={handleRequestNumber}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Generate"}
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      {requestedNumber && (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              borderRadius: "16px", // Rounded corners for the dialog
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="16px"
          >
            <Box display="flex" alignItems="center" gap={1}>
              <PhoneIphone
                sx={{
                  color: "#9EA5B1",
                  fontSize: "32px",
                  marginRight: "8px",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#616161",
                }}
              >
                Request New Number
              </Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <DialogContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              padding="16px"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "24px",
                  color: "#3A7AFE",
                  marginBottom: "16px",
                }}
              >
                Your Number: +{requestedNumber?.data.number}
              </Typography>
              {!sms ? (
                <>
                  <Typography variant="body1" sx={{ color: "#616161" }}>
                    Waiting for SMS
                  </Typography>
                  {timeRemaining !== null && (
                    <Typography
                      variant="body2"
                      sx={{ color: "#9EA5B1", marginTop: "4px" }}
                    >
                      Time remaining: {Math.floor(timeRemaining / 60)}:
                      {(timeRemaining % 60).toString().padStart(2, "0")}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "Inter", marginBottom: "8px" }}
                  >
                    Received SMS:
                  </Typography>
                  <Typography variant="body1">{sms}</Typography>
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "end", padding: "16px", paddingX: 2 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#FFFFF5",
                color: "#616161",
                textTransform: "none",
                fontWeight: 500,
                padding: "8px 24px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "red",
                  color: "#FFFFFF",
                },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default RequestNumberComponent;
